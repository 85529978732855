import { navigate } from '@reach/router';
import { fetchman } from '../../../utils/fetchman';
import { uiActions } from '../ui';
import { TypeOfActions as TypeOfUIActions } from '../ui/actions';
import { actions, TypeOfActions } from './actions';

/**
 * /api/login を叩く
 *
 * 成功時：dispatch(type.LOGIN_SUCCEED)して、/admin/settings/menuに飛ばす
 *
 * 失敗時：dispatch(type.FETCH_FAILED)
 */
// NOTE: uiDispatchをoptionalにしたらPROGRESS出し分けできる
export const login = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  id: string,
  password: string
) => {
  try {
    uiDispatch(uiActions.fetchProgress());
    const data = await fetchman(`/api/login`, { method: 'POST', body: { id, password } });
    dispatch(actions.loginSucceed(data.result));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('ログインに失敗しました'));
  }
};

/**
 * /api/logoutを叩く
 *
 * 成否に関わらず / に飛ばす
 */
export const logout = async () => {
  try {
    await fetchman('/api/logout', { method: 'POST' });
  } catch (e) {
    navigate('/login');
  }
};

/**
 * /api/auth/me を叩く
 *
 * 成功時：dispatch(type.LOGIN_SUCCEED)
 *
 * 失敗時：/ に飛ばす
 */
export const checkLogin = async (dispatch: React.Dispatch<TypeOfActions>) => {
  try {
    const user = await fetchman('/api/auth/me', { method: 'GET' });
    dispatch(actions.loginSucceed(user));
  } catch (error) {
    navigate('/login');
  }
};

/**
 * /api/auth/forget を叩く
 *
 * 成功時：dispatch(type.FORGET_USER_AUTH_SUCCEED)
 */
export const authForgetUser = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { name: string; mail: string }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    const data = await fetchman('/api/auth/forget', { method: 'POST', body: props });

    uiDispatch(uiActions.fetchSucceed('ユーザー認証に成功しました'));

    dispatch(actions.authForgetUserSucceed(data.result));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('ユーザー認証に失敗しました'));
  }
};

export const updateUser = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { pastId: string; updateData: { id: string; name: string; mail: string; password: string } }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    await fetchman(`/api/users/${props.pastId}`, { method: 'PUT', body: props.updateData });
    uiDispatch(uiActions.fetchSucceed('ユーザー情報の更新に成功しました'));

    dispatch(actions.updateUserSucceed());
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('ユーザー情報の更新に失敗しました'));
  }
};

export const csrfCreate = async (uiDispatch: React.Dispatch<TypeOfUIActions>) => {
  try {
    await fetchman(`/api/csrf`, { method: 'POST' });
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('トークン生成に失敗しました'));
  }
};
