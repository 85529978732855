import { createApiEndpoint } from '../env/EndPoint';

type QueryValue = string | number | boolean | string[] | number[] | undefined;
export type Query = Record<string, QueryValue>;

const createURLObj = (path: string) => {
  // NOTE: 正規表現等でチェックするようにすること
  return new URL(path);
};

const generateQueryParam = (query: Query) => {
  const params = new URLSearchParams();
  Object.entries(query)
    .flatMap(([name, value]) => (value !== undefined ? [{ name, value }] : []))
    .flatMap(({ name, value }) => {
      if (!Array.isArray(value)) {
        return [{ name, value }];
      }
      if (value.length === 0) {
        return [];
      }
      // valueが配列かつ長さが1以上の場合はカンマ区切りにする
      return [{ name, value: value.join(',') }];
    })
    .forEach(({ name, value }) => params.append(name, `${value}`));
  return params;
};

const appendQueryParam = (urlObj: URL, query?: Query) => {
  if (!query) {
    return urlObj;
  }
  const url = urlObj;
  const params = generateQueryParam(query);
  url.search = params.toString();
  return url;
};

export const generateURL = (path: string, query?: Query) =>
  appendQueryParam(createURLObj(createApiEndpoint(path)), query);
