const isBrowser = typeof window !== 'undefined';

const PROTOCOL = isBrowser ? window.location.protocol : 'http:';
const DOMAIN = isBrowser ? window.location.hostname : 'localhost';
const PORT = isBrowser ? window.location.port : '5000';

export const API_ENDPOINT = `${PROTOCOL}//${DOMAIN}:${PORT}`;

export const createApiEndpoint = (path: string) => {
  const endpoint = API_ENDPOINT;
  return `${endpoint}${path}`;
};
