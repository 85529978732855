import { generateURL, Query } from './queryParam';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
} as const;

type HttpMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
interface FetchmanOptions {
  method: HttpMethod;
  body?: any;
  query?: Query;
}

/**
 * mymeで使いたいオプションを付与したfetchAPIです
 * Content-Typeはapplication/json前提です
 *
 * @returns response.json()
 * @throws fetchがfetchに失敗したException
 * @throws response.json()がパースに失敗したException
 */
export const fetchman = async (path: string, options: FetchmanOptions): Promise<any> => {
  const { method, body, query } = options;
  const url = `${generateURL(path, query)}`;
  return fetch(url, {
    ...defaultOptions,
    method,
    body: body ? JSON.stringify(body) : undefined,
  }).then((response) => {
    if (response.status === 204) return undefined;
    if (response.status === 200 || response.status === 201) return response.json();

    throw new Error();
  });
};
