/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable */
import React from 'react';
import './src/styles/reset.css'
import { AuthContextProvider } from './src/modules/general/auth/index';
export const wrapRootElement = ({ element }) => (
  <AuthContextProvider>
      { element }
  </AuthContextProvider>
);
