// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-settings-design-tsx": () => import("./../../../src/pages/admin/settings/design.tsx" /* webpackChunkName: "component---src-pages-admin-settings-design-tsx" */),
  "component---src-pages-admin-settings-menu-tsx": () => import("./../../../src/pages/admin/settings/menu.tsx" /* webpackChunkName: "component---src-pages-admin-settings-menu-tsx" */),
  "component---src-pages-admin-settings-option-tsx": () => import("./../../../src/pages/admin/settings/option.tsx" /* webpackChunkName: "component---src-pages-admin-settings-option-tsx" */),
  "component---src-pages-admin-settings-soldout-tsx": () => import("./../../../src/pages/admin/settings/soldout.tsx" /* webpackChunkName: "component---src-pages-admin-settings-soldout-tsx" */),
  "component---src-pages-admin-takeout-basic-information-settings-tsx": () => import("./../../../src/pages/admin/takeout/basic-information-settings.tsx" /* webpackChunkName: "component---src-pages-admin-takeout-basic-information-settings-tsx" */),
  "component---src-pages-admin-takeout-calendar-tsx": () => import("./../../../src/pages/admin/takeout/calendar.tsx" /* webpackChunkName: "component---src-pages-admin-takeout-calendar-tsx" */),
  "component---src-pages-admin-takeout-holiday-tsx": () => import("./../../../src/pages/admin/takeout/holiday.tsx" /* webpackChunkName: "component---src-pages-admin-takeout-holiday-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */)
}

