import { Reducer } from 'react';
import { TypeOfActions } from './actions';
import * as types from './types';

export interface State {
  loading: boolean;
  message: {
    isShow: boolean;
    severity: 'success' | 'error' | 'info';
    text: string;
  };
  isIconSideMenuFold: boolean;
}

export const initialState: State = {
  loading: false,
  message: {
    isShow: false,
    severity: 'success',
    text: '',
  },
  isIconSideMenuFold: typeof window !== 'undefined' ? localStorage.getItem('isIconSideMenuFold') === 'true' : false,
};

export const reducer: Reducer<State, TypeOfActions> = (state, action) => {
  switch (action.type) {
    case types.FETCH_PROGRESS:
      return {
        ...state,
        loading: true,
        message: {
          ...state.message,
          isShow: false,
        },
      };
    case types.FETCH_SUCCEED:
      return {
        ...state,
        loading: false,
        message: action.payload.message
          ? {
              isShow: true,
              severity: 'success',
              text: action.payload.message,
            }
          : {
              ...state.message,
              isShow: false,
              text: '',
            },
      };
    case types.FETCH_FAILED:
      return {
        ...state,
        loading: false,
        message: {
          isShow: true,
          severity: 'error',
          text: action.payload.message,
        },
      };
    case types.FEEDBACK_MESSAGE_CLOSED:
      return {
        ...state,
        message: {
          ...state.message,
          isShow: false,
        },
      };
    case types.TOGGLE_ICON_SIDE_MENU_FOLD:
      return {
        ...state,
        isIconSideMenuFold: action.payload.toggledIconSideMenuFold,
      };
    default:
      // eslint-disable-next-line
      const _: never = action;
      return state;
  }
};
