import { actions, TypeOfActions } from './actions';

/**
 * アイコンサイドメニューの開閉状態を切り替える
 */
export const toggleIconSideMenuFold = async (dispatch: React.Dispatch<TypeOfActions>, isIconSideMenuFold: boolean) => {
  const toggledIconSideMenuFold = !isIconSideMenuFold;

  localStorage.setItem('isIconSideMenuFold', toggledIconSideMenuFold.toString());

  dispatch(actions.toggleIconSideMenuFold(toggledIconSideMenuFold));
};
