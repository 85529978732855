import { DTOUser } from 'models';
import * as types from './types';

/**
 * ログイン成功時アクション
 */
export interface UserProps {
  result: { id: string; authority: string };
}

const loginSucceed = (user: UserProps) => ({
  type: types.LOGIN_SUCCEED,
  payload: {
    user,
  },
});

/**
 * ログアウト成功時アクション
 */
const logoutSucceed = () => ({
  type: types.LOGOUT_SUCCEED,
});

const authForgetUserSucceed = (forgetUser: DTOUser) => ({
  type: types.AUTH_FORGET_USER_SUCCEED,
  payload: {
    forgetUser,
  },
});

const updateUserSucceed = () => ({
  type: types.UPDATE_USER_SUCCEED,
  payload: {
    forgetUser: undefined,
  },
});

const resetUserAuth = () => ({
  type: types.RESET_FORGET_USER_AUTH,
  payload: {
    forgetUser: undefined,
  },
});

export const actions = {
  loginSucceed,
  logoutSucceed,
  authForgetUserSucceed,
  updateUserSucceed,
  resetUserAuth,
};
export type TypeOfActions = ReturnType<
  | typeof loginSucceed
  | typeof logoutSucceed
  | typeof authForgetUserSucceed
  | typeof updateUserSucceed
  | typeof resetUserAuth
>;
